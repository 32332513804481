<template>
  <v-container fluid>
    <ValidationObserver ref="obs" v-slot="{ invalid }">
      <v-card
        class="d-flex justify-center flex-column mx-auto my-2 flat"
        width="1200"
        color="#fff"
      >
        <v-card-title class="d-flex justify-center pa-0 my-2">
          システム設定
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="d-flex flex-column">
          <v-row>
            <v-col class="d-flex mb-2" cols="12" sm="12" md="12" lg="12" xl="12">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="ml-2" v-bind="attrs" v-on="on" color="orange">mdi-help-circle-outline</v-icon>
                </template>
                <div>登校日当日の保護者による出欠登録を制限する時刻を設定できます</div>
                <div>未設定であれば制限しません</div>
              </v-tooltip>
              <v-menu
                  ref="menu"
                  v-model="timepicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="syukketuLimitTime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="syukketuLimitTime"
                    label="出欠登録制限時刻"
                    placeholder="HH:MM"
                    readonly
                    outlined
                    dense
                    clearable
                    hide-details="auto"
                    append-icon="mdi-clock-edit-outline"
                    v-bind="attrs"
                    v-on="on"
                    ></v-text-field>
                </template>
                <v-time-picker
                  v-if="timepicker"
                  v-model="syukketuLimitTime"
                  :allowed-hours="allowedHours"
                  :allowed-minutes="allowedStep"
                  full-width
                  @click:minute="$refs.menu.save(syukketuLimitTime)"
                  ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
          <ValidationProvider name="制限超過時表示コメント" rules="max:50" v-slot="{ errors }">
            <v-row dense>
              <v-col class="d-flex mb-2" cols="12" sm="12" md="12" lg="12" xl="12">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="ml-2" v-bind="attrs" v-on="on" color="orange">mdi-help-circle-outline</v-icon>
                  </template>
                  <div>制限する時刻を超過した際に保護者へ掲示する文言となります</div>
                  <div>例文 ⇒ hh:mm以降は電話にてご連絡ください。</div>
                </v-tooltip>
                <v-text-field
                  v-model="syukketuLimitTimeComment"
                  label="制限超過時表示コメント"
                  placeholder=""
                  :error-messages="errors[0]"
                  outlined
                  dense
                  :counter="50"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
          </ValidationProvider>
          <ValidationProvider name="出欠ロック日付" rules="required" v-slot="{ errors }">
            <v-row>
              <v-col class="d-flex mb-2" cols="12" sm="12" md="12" lg="12" xl="12">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="ml-2" v-bind="attrs" v-on="on" color="orange">mdi-help-circle-outline</v-icon>
                  </template>
                  <div>過去の出欠に対して先生側からの変更を制限する日付を設定できます</div>
                  <div>※指定日以前の出欠の変更が出来なくなります</div>
                </v-tooltip>
                <v-text-field
                  v-model="syukketuLockDate"
                  prepend-icon="$cet_required"
                  label="出欠ロック日付"
                  type="date"
                  :error-messages="errors[0]"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
          </ValidationProvider>
        </v-card-text>
        <v-card-actions>
          <v-btn color="accent" class="mt-0 black--text" @click="saveSettingEdit" :disabled="invalid" width="100%">更新</v-btn>
        </v-card-actions>  
      </v-card>
    </ValidationObserver>
  </v-container>
</template>

<script>
import { httpsCallable, getFunctions } from "firebase/functions";

export default {
  data() {
    return {
      timepicker: false,
      // 出欠ロック時刻
      syukketuLimitTime: "",
      // 制限超過時表示コメント
      syukketuLimitTimeComment: "",
      // 出欠ロック日付
      syukketuLockDate: "",
    };
  },
  async created() {
    // ローディング開始
    this.$root.loading = true;

    const data = {
      funcName: "loadSettingEdit",
    };

    const functions = getFunctions(this.$firebase);
    functions.region = "asia-northeast1";
    const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
    await schoolAidHomeLogined(data).then((res) => {
      this.syukketuLimitTime = res.data.syukketuLimitTime;
      this.syukketuLimitTimeComment = res.data.syukketuLimitTimeComment;
      this.syukketuLockDate = res.data.syukketuLockDate;
      
      // ローディング終了
      this.$root.loading = false;
    }).catch((e) => {
      console.log(e.message);
      this.$root.loading = false;
    });
  },
  methods: {
    allowedHours: v => 4 < v && v < 24,
    allowedStep: m => m % 10 === 0,
    // 設定編集
    async saveSettingEdit() {
      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: "saveSettingEdit",
        syukketuLimitTime: this.syukketuLimitTime,
        syukketuLimitTimeComment: this.syukketuLimitTimeComment,
        syukketuLockDate: this.syukketuLockDate,
      };
      await schoolAidHomeLogined(data).then((res) => {
        // ローディング非表示
        this.$root.loading = false;
        console.log(res.data);
        if (res.data == "success"){
          alert("更新しました。");
        }  
        else {
          alert("データ更新に失敗しました。");
        }
      });
    },
  },
};
</script>